import type { RouterOptions } from '@nuxt/schema'

const scrollIfNeeded = (to, from, savedPosition) => {
  if (to?.path === from?.path) { return }

  const toName = to?.name?.split('___')[0] || null
  const fromName = from?.name?.split('___')[0] || null

  const fromNameBase = fromName?.split('-')[0] || null
  const toNameBase = toName?.split('-')[0] || null

  const modalPages = [
    'cities-slug',
    'cities-venues-slug-secondaryslug',
    'cities-venues-slug',
    'clusters-slug',
    'events-slug',
    'index-newsletter',
    'news-slug',
    'participants-slug',
    'venues-slug',
  ]

  if (to.hash && document.querySelector(to.hash)) {
    document.querySelector(to.hash).scrollIntoView({
      behavior: "smooth"
    })
  }
  else if (modalPages?.includes(toName) && fromNameBase === toNameBase || modalPages?.includes(fromName) && fromNameBase === toNameBase ) { return  }
  else if (modalPages?.includes(toName)) {
    return { left: 0, top: 0, behavior: 'smooth' }
  } else {
    return { left: 0, top: 0 }
  }
}

export default <RouterOptions> {
  scrollBehavior (to, from, savedPosition) {
    const nuxtApp = useNuxtApp()
    return new Promise(async (resolve) => {
      nuxtApp.hooks.hookOnce('page:finish', async () => {
        await nextTick()
        resolve(scrollIfNeeded(to, from, savedPosition))
      })
    })
  }
}
