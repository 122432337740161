import { default as _91slug_93EZdZHHI0dkMeta } from "/workspace/nuxt/pages/[slug].vue?macro=true";
import { default as _91slug_93pr3B64Pc4YMeta } from "/workspace/nuxt/pages/cities/[slug].vue?macro=true";
import { default as citiesx8pBRnkO9XMeta } from "/workspace/nuxt/pages/cities.vue?macro=true";
import { default as _91slug_932rTUoYf1NiMeta } from "/workspace/nuxt/pages/clusters/[slug].vue?macro=true";
import { default as clustersF373B7rCsQMeta } from "/workspace/nuxt/pages/clusters.vue?macro=true";
import { default as _91slug_933XnHeyE3RVMeta } from "/workspace/nuxt/pages/events/[slug].vue?macro=true";
import { default as eventsw3f86XFGfaMeta } from "/workspace/nuxt/pages/events.vue?macro=true";
import { default as _91slug_93gGMoW1YGh8Meta } from "/workspace/nuxt/pages/focus-week/[slug].vue?macro=true";
import { default as focus_45weekMfHvSVf5iQMeta } from "/workspace/nuxt/pages/focus-week.vue?macro=true";
import { default as newsletterQJFu4cnVNEMeta } from "/workspace/nuxt/pages/index/newsletter.vue?macro=true";
import { default as indexYicQbbOvh5Meta } from "/workspace/nuxt/pages/index.vue?macro=true";
import { default as mapVsLAUDUJuIMeta } from "/workspace/nuxt/pages/map.vue?macro=true";
import { default as _91slug_93CQQJCWyMWoMeta } from "/workspace/nuxt/pages/news/[slug].vue?macro=true";
import { default as newsJ8lbpdZQsmMeta } from "/workspace/nuxt/pages/news.vue?macro=true";
import { default as page_45previewJJPKdABYq1Meta } from "/workspace/nuxt/node_modules/@brambergvd/nuxt-base-layer/pages/page-preview.vue?macro=true";
import { default as _91slug_93RufbPbksJjMeta } from "/workspace/nuxt/pages/participants/[slug].vue?macro=true";
import { default as participants9tLmzMiS7nMeta } from "/workspace/nuxt/pages/participants.vue?macro=true";
import { default as partnersvCBvXGGJ2YMeta } from "/workspace/nuxt/pages/partners.vue?macro=true";
import { default as presslGXyP6smzwMeta } from "/workspace/nuxt/pages/press.vue?macro=true";
import { default as _91slug_93kyykW2ogSCMeta } from "/workspace/nuxt/pages/publications/[slug].vue?macro=true";
import { default as publicationsn6piZ0ljqSMeta } from "/workspace/nuxt/pages/publications.vue?macro=true";
import { default as _91slug_935wyPKQiosrMeta } from "/workspace/nuxt/pages/venues/[slug].vue?macro=true";
import { default as venuesj147kCWHD4Meta } from "/workspace/nuxt/pages/venues.vue?macro=true";
import { default as component_45stubRotpj5dKP6Meta } from "/workspace/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRotpj5dKP6 } from "/workspace/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug()",
    component: () => import("/workspace/nuxt/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ca",
    path: "/ca/:slug()",
    component: () => import("/workspace/nuxt/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___es",
    path: "/es/:slug()",
    component: () => import("/workspace/nuxt/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/cities",
    component: () => import("/workspace/nuxt/pages/cities.vue").then(m => m.default || m),
    children: [
  {
    name: "cities-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/cities/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cities___ca",
    path: "/ca/ciutats",
    component: () => import("/workspace/nuxt/pages/cities.vue").then(m => m.default || m),
    children: [
  {
    name: "cities-slug___ca",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/cities/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/workspace/nuxt/pages/cities.vue").then(m => m.default || m),
    children: [
  {
    name: "cities-slug___es",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/cities/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "clusters___en",
    path: "/clusters",
    component: () => import("/workspace/nuxt/pages/clusters.vue").then(m => m.default || m),
    children: [
  {
    name: "clusters-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/clusters/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "clusters___ca",
    path: "/ca/clusters",
    component: () => import("/workspace/nuxt/pages/clusters.vue").then(m => m.default || m),
    children: [
  {
    name: "clusters-slug___ca",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/clusters/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "clusters___es",
    path: "/es/clusteres",
    component: () => import("/workspace/nuxt/pages/clusters.vue").then(m => m.default || m),
    children: [
  {
    name: "clusters-slug___es",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/clusters/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/workspace/nuxt/pages/events.vue").then(m => m.default || m),
    children: [
  {
    name: "events-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/events/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "events___ca",
    path: "/ca/esdeveniments",
    component: () => import("/workspace/nuxt/pages/events.vue").then(m => m.default || m),
    children: [
  {
    name: "events-slug___ca",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/events/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "events___es",
    path: "/es/eventos",
    component: () => import("/workspace/nuxt/pages/events.vue").then(m => m.default || m),
    children: [
  {
    name: "events-slug___es",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/events/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "focus-week___en",
    path: "/focus-weeks",
    component: () => import("/workspace/nuxt/pages/focus-week.vue").then(m => m.default || m),
    children: [
  {
    name: "focus-week-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/focus-week/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "focus-week___ca",
    path: "/ca/focus-weeks",
    component: () => import("/workspace/nuxt/pages/focus-week.vue").then(m => m.default || m),
    children: [
  {
    name: "focus-week-slug___ca",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/focus-week/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "focus-week___es",
    path: "/es/focus-weeks",
    component: () => import("/workspace/nuxt/pages/focus-week.vue").then(m => m.default || m),
    children: [
  {
    name: "focus-week-slug___es",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/focus-week/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/workspace/nuxt/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-newsletter___en",
    path: "/newsletter",
    component: () => import("/workspace/nuxt/pages/index/newsletter.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___ca",
    path: "/ca",
    component: () => import("/workspace/nuxt/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-newsletter___ca",
    path: "butlleti-informatiu",
    component: () => import("/workspace/nuxt/pages/index/newsletter.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/workspace/nuxt/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-newsletter___es",
    path: "boletin-informativo",
    component: () => import("/workspace/nuxt/pages/index/newsletter.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "map___en",
    path: "/map",
    component: () => import("/workspace/nuxt/pages/map.vue").then(m => m.default || m)
  },
  {
    name: "map___ca",
    path: "/ca/map",
    component: () => import("/workspace/nuxt/pages/map.vue").then(m => m.default || m)
  },
  {
    name: "map___es",
    path: "/es/map",
    component: () => import("/workspace/nuxt/pages/map.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/news",
    component: () => import("/workspace/nuxt/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: "news-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/news/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "news___ca",
    path: "/ca/noticies",
    component: () => import("/workspace/nuxt/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: "news-slug___ca",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/news/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "news___es",
    path: "/es/noticias",
    component: () => import("/workspace/nuxt/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: "news-slug___es",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/news/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "page-preview",
    path: "/page-preview",
    component: () => import("/workspace/nuxt/node_modules/@brambergvd/nuxt-base-layer/pages/page-preview.vue").then(m => m.default || m)
  },
  {
    name: "participants___en",
    path: "/participants",
    component: () => import("/workspace/nuxt/pages/participants.vue").then(m => m.default || m),
    children: [
  {
    name: "participants-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/participants/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "participants___ca",
    path: "/ca/participants",
    component: () => import("/workspace/nuxt/pages/participants.vue").then(m => m.default || m),
    children: [
  {
    name: "participants-slug___ca",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/participants/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "participants___es",
    path: "/es/participantes",
    component: () => import("/workspace/nuxt/pages/participants.vue").then(m => m.default || m),
    children: [
  {
    name: "participants-slug___es",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/participants/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "partners___en",
    path: "/partners",
    component: () => import("/workspace/nuxt/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: "partners___ca",
    path: "/ca/socis",
    component: () => import("/workspace/nuxt/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: "partners___es",
    path: "/es/socios",
    component: () => import("/workspace/nuxt/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: "press___en",
    path: "/press",
    component: () => import("/workspace/nuxt/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "press___ca",
    path: "/ca/premsa",
    component: () => import("/workspace/nuxt/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "press___es",
    path: "/es/prensa",
    component: () => import("/workspace/nuxt/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "publications___en",
    path: "/publications",
    component: () => import("/workspace/nuxt/pages/publications.vue").then(m => m.default || m),
    children: [
  {
    name: "publications-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/publications/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "publications___ca",
    path: "/ca/publications",
    component: () => import("/workspace/nuxt/pages/publications.vue").then(m => m.default || m),
    children: [
  {
    name: "publications-slug___ca",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/publications/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "publications___es",
    path: "/es/publications",
    component: () => import("/workspace/nuxt/pages/publications.vue").then(m => m.default || m),
    children: [
  {
    name: "publications-slug___es",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/publications/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "venues___en",
    path: "/venues",
    component: () => import("/workspace/nuxt/pages/venues.vue").then(m => m.default || m),
    children: [
  {
    name: "venues-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/venues/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "venues___ca",
    path: "/ca/seus",
    component: () => import("/workspace/nuxt/pages/venues.vue").then(m => m.default || m),
    children: [
  {
    name: "venues-slug___ca",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/venues/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "venues___es",
    path: "/es/sedes",
    component: () => import("/workspace/nuxt/pages/venues.vue").then(m => m.default || m),
    children: [
  {
    name: "venues-slug___es",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/venues/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubRotpj5dKP6Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubRotpj5dKP6
  }
]